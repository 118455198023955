import React from 'react'
import styled from 'styled-components'
import { Theme, LinkIcon } from '@pga/pga-component-library'
import { Card, CardImg, CardText, CardBody, CardHeader, CardTitle } from 'reactstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { DropdownIcon } from '../components/MobileSectionMap/index'

const ASSET_PREFIX = '/uploads/workingolf'
const CAREER_SERVICES_LINK = 'https://pgaservices.force.com/CareerServices/s/?ccc=7014Q000002iaM1QAI'

const MediumHeader = styled.h3`
  font-size: ${props => props.fs || '24px'};
  font-weight: ${props => props.fw || '600'};
  line-height: 1.3;
  font-family: ${Theme.fonts.PlayfairDisplay};
  color: ${props => props.color || Theme.colors.gold};
`

const MediumHeaderNavy = styled(MediumHeader)`
  color: ${Theme.colors.primary};
`

const VideoLabel = styled(MediumHeaderNavy)`
  font-size: 22px;
  @media(max-width: 767.98px) {
    font-size: 18px;
  }
  @media(max-width: 575.98px) {
    font-size: 16px;
  }
`

const P = styled.p`
  color: ${props => props.color || Theme.colors.primary};
  font-size: 16px;
  margin-top: 1rem;
`

const WhiteButton = styled.a`
  color: ${Theme.colors.gold};
  background-color: white;
  font-size: 16px;
  font-weight: 600;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: .5rem 3.5rem;
  cursor: pointer;

  border-radius: 25px;
  border-color: ${Theme.colors.gold};
  border-style: solid;
  border-width: 3px;

  &:hover {
    background-color: #efefef;
  }
`

const CardTextStyled = styled(CardText)`
  color: ${Theme.colors.gold};
  font-size: 16px;
`

const CardStyled = styled(Card)`
  transition: all .4s ease-in-out;
  &:hover {
    transform: scale(1.2);
    z-index: 10;
  }
`

const WhiteLink = styled.a`
  color: white;
  font-size: 1rem;
  text-decoration: underline;
  &:hover {
    color: ${Theme.colors.darkgrey};
  }
`

const BannerImageWrapper = styled.div`
.jumbotron {
    @media(min-width: 1200px) {
      padding: 0 0 30%;
    }
  }
  .jumbotron-body {
    @media(max-width: 575.98px) {
      transform: translateY(-55%);
    }
  }
`

const JumbotronImage = styled.div`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-image: ${props => `linear-gradient(to right, rgba(245, 246, 252, 0.8), rgba(20, 19, 93, 0.05)), url("${props.desktop}");`};
  @media(max-width: 575.98px) {
    background-image: ${props => `linear-gradient(to right, rgba(245, 246, 252, 0.99), rgba(20, 19, 93, 0.05)), url("${props.mobile}")`};
  }
`

const BannerHeader = styled.h1`
  width: 75%;
  @media(min-width: 1200px) {
    width: 50%;
  }
`

export const CtaBanner = ({
  bgColor = 'bg-secondary',
  text = 'Ready To Turn Your Passion Into A Career?',
  label = 'Ask Us How!',
  link = CAREER_SERVICES_LINK
}) => (
  <div className={`${bgColor} p-4 d-flex justify-content-center align-items-center flex-column`}>
    <MediumHeader>
      {text}
    </MediumHeader>
    <WhiteButton href={link}>{label}</WhiteButton>
  </div>
)

const ImageCard = ({ src, text, name, title }) => (
  <div className='col-lg-4 col-md-6 mb-4'>
    <CardStyled className='h-100'>
      {src && <CardImg top width='100%' src={src} alt='Card image' />}
      <CardBody>
        {title && <CardTextStyled><strong>{title}</strong></CardTextStyled>}
        <CardTextStyled>{text}</CardTextStyled>
        {name && <CardTextStyled><strong>- {name}</strong></CardTextStyled>}
      </CardBody>
    </CardStyled>
  </div>
)

export const ImageCards = () => {
  const imgCards = [
    { src: `${ASSET_PREFIX}/RAB_6051-min.JPG`, name: 'Ben Polland, PGA', text: '"I love the game of golf. Growing up everyone says do what you love, and I chose a career in golf because of my passion for it. I’m excited to go to work every day."' },
    { src: `${ASSET_PREFIX}/QW9A8076.jpg`, name: 'Kendall Murphy, PGA', text: '"Whether I’m in my office or on the golf course I’m getting paid to bring people into the game of golf. How can you not love that? That’s what it’s all about."' },
    { src: `${ASSET_PREFIX}/QW9A8391.jpg`, name: 'Cathy Kim, PGA', text: '"Being a PGA Coach allows me to make a career out of what I love the most."' }
  ]
  return (
    <div className='container mt-4 mb-3'>
      <MediumHeaderNavy fs='28px'>Testimonials</MediumHeaderNavy>
      <div className='row'>
        {imgCards.map(({ src, text, name }, i) => <ImageCard {...{ src, text, name, i }} key={i} />)}
      </div>
    </div>
  )
}

export const VideosWrapper = () => (
  <div className='bg-light'>
    <div className='container'>
      <MediumHeaderNavy fs='28px' className='py-4'>See What's Possible For You As A PGA Member.</MediumHeaderNavy>
      <VideoLabel>Bring Championships To Life</VideoLabel>
      <div className='row pb-4'>
        <div className='col'>
          <div className='embed-responsive embed-responsive-16by9'>
            <iframe frameBorder={0} src='https://player.vimeo.com/video/698663657' />
          </div>
        </div>
      </div>

      <div className='row pb-4'>
        <div className='col-md-6 col-sm-12'>
          <VideoLabel>Compete With The Best</VideoLabel>
          <div className='embed-responsive embed-responsive-16by9'>
            <iframe frameBorder={0} src='https://player.vimeo.com/video/555323718' />
          </div>
        </div>
        <div className='col-md-6 col-sm-12'>
          <VideoLabel className='text-md-right pt-3 pt-md-0'>Learn In Style</VideoLabel>
          <div className='embed-responsive embed-responsive-16by9'>
            <iframe frameBorder={0} src='https://player.vimeo.com/video/741881582' />
          </div>
        </div>
      </div>
    </div>
    <CtaBanner
      text="See More Of What's Possible"
      label='Click here'
      link='/career-services/see-whats-possible'
    />
  </div>
)

export const CarouselImages = () => {
  const imgCards = [
    { src: `${ASSET_PREFIX}/carousel1.jpg`, title: 'Leave Your Legacy In The Game', text: 'PGA Members are recognized around the industry as teachers and leaders helping evolve the game of golf.' },
    { src: `${ASSET_PREFIX}/carousel2.jpg`, title: 'Create A Thriving Career', text: 'Discover all the opportunities available as a member—including careers in Executive Management, Golf Operations and Teaching & Coaching.' },
    { src: `${ASSET_PREFIX}/carousel3.jpg`, title: 'Play With The Best', text: 'Compete in numerous tournaments across the country with a chance to qualify for mens, womens and senior Major Championships.' }
  ]

  return (
    <div className='container mt-4 mb-3'>
      <MediumHeaderNavy fs='28px'>Why Become A PGA Member?</MediumHeaderNavy>
      <div className='row'>
        {imgCards.map(({ src, text, title }, i) => <ImageCard {...{ src, text, title, i }} key={i} />)}
      </div>
    </div>
  )
}

export const IconSection = () => (
  <div className='bg-white pt-4'>
    <div className='container'>
      <MediumHeader fs='28px'>Take Your First Step To Becoming A PGA Member.</MediumHeader>
      <MediumHeaderNavy fw='500'>It's free and simple to get started— click one of the links below</MediumHeaderNavy>
      <div className='row mt-4'>
        <div className='col-lg-4 col-md-6 col-sm-12'>
          <div className='d-flex justify-content-center mb-3'>
            <img src={`${ASSET_PREFIX}/icons/Map.svg`} height='70px' />
          </div>
          <Card>
            <CardHeader tag='h5' className='text-secondary d-flex justify-content-between'>
              My PGA Journey
              <DropdownIcon />
            </CardHeader>
            <CardBody>
              <CardText>
                A free, interactive experience where leading PGA Members share their insights, passion and career guidance for a successful career in the golf industry.
              </CardText>
              <a href='https://mypgajourney.com/' className='text-info' target='_blank' rel='noopener noreferrer'>Learn More <LinkIcon className='ml-1 p-0' /></a>
            </CardBody>
          </Card>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12'>
          <div className='d-flex justify-content-center mb-3'>
            <img src={`${ASSET_PREFIX}/icons/Magnifying Glass.svg`} height='70px' />
          </div>
          <Card>
            <CardHeader tag='h5' className='text-secondary d-flex justify-content-between'>
              PGA Job Board
              <DropdownIcon />
            </CardHeader>
            <CardBody>
              <CardText>
                Create an account and get access to hundreds of positions available to find your next career.
              </CardText>
              <a href='https://jobs.pga.org/' className='text-info' target='_blank' rel='noopener noreferrer'>Learn More <LinkIcon className='ml-1 p-0' /></a>
            </CardBody>
          </Card>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12'>
          <div className='d-flex justify-content-center mb-3'>
            <i className='fa fa-commenting' style={{ fontSize: '4.36rem', color: 'black' }} />
          </div>
          <Card>
            <CardHeader tag='h5' className='text-secondary d-flex justify-content-between'>
              Speak With A PGA Career Consultant
              <DropdownIcon />
            </CardHeader>
            <CardBody>
              <CardText>
                Interested in a position inside the game? Speak to a PGA Recruitment Team Member to help you find the right opportunity.
              </CardText>
              <a href={CAREER_SERVICES_LINK} className='text-info' target='_blank' rel='noopener noreferrer'>Learn More <LinkIcon className='ml-1 p-0' /></a>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  </div>
)

export const Steps = () => {
  const steps = [
    {
      icon: `${ASSET_PREFIX}/icons/Bank.svg`,
      title: 'PGA Golf Management University',
      text: "Earn your bachelor's degree and PGA Membership from an accredited university.",
      link: '/membership/university-program'
    },
    {
      icon: `${ASSET_PREFIX}/icons/Golf Caddy.svg`,
      title: 'PGA Associate Program',
      text: 'Discover how to evolve your game and career with a PGA Membership.',
      link: '/membership/associate-program'
    },
    {
      icon: `${ASSET_PREFIX}/icons/Golf Flag.svg`,
      title: 'PGA Affiliate Program',
      text: 'See how you can start earning your eligibility to become a PGA Associate.',
      link: '/membership/affiliate-program'
    }
  ]
  return (
    <div className='bg-light py-4'>
      <div className='container'>
        <MediumHeader fs='28px'>Ways To Become A PGA Member.</MediumHeader>
        <MediumHeaderNavy fs='18px' fw='500'>Whether you're a student or looking to make a career move, learn about the three tracks.</MediumHeaderNavy>
        <div className='row'>
          {steps.map(({ icon, title, text, link }, i) => (
            <div className='col-lg-4 col-md-6 col-sm-12 mt-4' key={i}>
              <Card className='bg-secondary card-stretch h-100'>
                <div className='d-flex flex-column align-items-center p-3'>
                  <img src={icon} />
                  <CardTitle tag='h4' className='mb-0 text-center text-white fw-500'>{title}</CardTitle>
                </div>
                <CardBody className='py-0'>
                  <P className='text-white'>
                    {text}
                  </P>
                  <WhiteLink href={link}>Learn More <LinkIcon className='ml-1 p-0' /></WhiteLink>
                </CardBody>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const BannerImage = () => (
  <BannerImageWrapper>
    <div className='jumbotron jumbotron-fluid jumbotron-custom-md editable'>
      <JumbotronImage 
        desktop={`${ASSET_PREFIX}/Recrutiment-Page-Header-Image-4.jpeg`}
        mobile={`${ASSET_PREFIX}/Recruitment-Page-Header-Mobile-Image.jpeg`}
      />
      <div className='jumbotron-body'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-9 col-sm-12'>
              <BannerHeader>Your Successful Career In Golf Starts Right Here.</BannerHeader>
              <P className='w-50'>
                Play and work in the game you love with a PGA Membership. <br />As a member, you get access to a network of 28,000 golf professionals <br />to help create a fulfilling career in golf.
              </P>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BannerImageWrapper>
)

export default ({ location }) => (
  <Layout location={location} bgColor='#e7eef6' hideSubNav>
    <SEO title='Recruiting: Work in Golf' />
    <BannerImage />
    <CtaBanner />
    <VideosWrapper />
    <ImageCards />
    <CtaBanner />
    <CarouselImages />
    <IconSection />
    <Steps />
    <CtaBanner bgColor='bg-light' />
  </Layout>
)
