import React, { useState } from 'react'
import styled from 'styled-components'
import Parser from 'html-react-parser'
import { Theme, BackArrowIcon } from '@pga/pga-component-library'
import image from '../../images/loupe.png'
import TabContent from '../TabContent'


export const MobileSectionsBox = styled.div`
  box-shadow: 0 5px 10px 0 ${Theme.colors.primaryShadow}; 
  background-color: ${Theme.colors.primary};
  position: fixed;
  display: block!important;
  z-index: 1050;
  top: 53px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`

export const SectionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li{
    padding: 15px 32px;
    & + li{
      border-top: 2px solid rgba(127,145,165,0.5);
    }
    &:hover{
      background-color: rgba(255,255,255,0.09);
    }
  }
`

export const SideClose = styled(BackArrowIcon)`
  cursor: pointer;
  object-fit: contain;
  padding: 12px 16px;
  display: block;
  svg {
    width: 16px;
    height: 16px;
    g{
      stroke: #7B91A7;
    }
  }
`

export const MenuBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 13px;
  margin-bottom: 30px;
  border: 1px solid ${Theme.colors.greyBlue40};
  border-radius: 2px;
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: right;
  background-color: ${({open}) => open ? Theme.colors.greyBlue15 : ''};
  cursor: pointer;
`

export const DropdownIcon = styled.i.attrs(({ open }) => ({
  className: open ? 'fa fa-angle-up' : 'fa fa-angle-down'
}))`
  &.fa {
    padding: 0 0 0 10px;
    font-size: 18px;
    font-weight: bold;
  }
`

export const MapSelect = styled.div`
  background: ${({active}) => !active ? `url(${image})` : Theme.colors.white};
  background-repeat: no-repeat;
  background-position: center 70px;
  box-shadow: 0 5px 10px 0 rgba(0, 35, 75, 0.15);
  padding: 30px;
  min-height: 340px;
`

export const MapSelectInput = ({isActive, setValue, hasName, setName, allSectionsYaml}) => {
  const [isOpen, setState] = useState(false);

  if (isOpen) {
    document.body.classList.add('modal-open')
  } else {
    document.body.classList.remove('modal-open')
  }

  return (
    <div>
      <MenuBtn onClick={() => setState(!isOpen)}> {!isActive ? 'Select a Section' : hasName + ' Section'}<DropdownIcon /></MenuBtn>
      {isOpen
        ? <SectionsListWrapper allSectionsYaml={allSectionsYaml} isOpen={isOpen} setState={setState} isActive={isActive} setValue={setValue} hasName={hasName} setName={setName} />
        : null
      }
    </div>
   )
}


export const MobileSectionMap = ({ allSectionsYaml }) => {

  const [isActive, setValue] = useState(false);
  const [hasName, setName] = useState(false);

  return (
    <div className="container my-2">
          <MapSelectInput allSectionsYaml={allSectionsYaml} isActive={isActive} setValue={setValue} hasName={hasName} setName={setName}></MapSelectInput>
          <MapSelect active={isActive}>
          <p></p>
            <div className="tab-content">
              <div className={"tab-pane " + (!isActive ? 'active' : '')} aria-labelledby="home-tab">
                <span className="label">Section</span>
              </div>
              {allSectionsYaml.edges.map(({node}) => (
                isActive.isActive === node.attr 
                  ? <TabContent className="tab-pane active" node={node} />
                  : <TabContent className="tab-pane" node={node} />
              ))}
           </div>
         </MapSelect>
  </div>
  )
}

export const SectionsListWrapper = ({ allSectionsYaml, isOpen, setState, isActive, setValue, hasName, setName }) => {

  const listContent = allSectionsYaml.edges.map(({node}) => {
    return (
      isActive.isActive === node.attr 
      ? <TabContent className="tab-pane active"  node={node} />
      : <TabContent className="tab-pane" node={node} />
      )
    }
  )

  const changeActive = event => {
    event.persist();
    return (
      setValue({isActive: event.target.getAttribute("id")}),
      setState(!isOpen),
      setName(event.target.getAttribute("data-attr"))
    )
  }

  return (
  <div>
    <MobileSectionsBox >
      <SideClose onClick={() => setState(!isOpen)}  />
      <SectionsList>
        {allSectionsYaml.edges.map(({node}) => (
          <li onClick={changeActive} id={Parser(node.attr)} data-attr={Parser(node.Section)}>
            {Parser(node.Section)} Section
          </li>
        ))}
      </SectionsList>
    </MobileSectionsBox>
      {listContent}
  </div>
  )
}


export default MobileSectionMap