import React from 'react'

function TabContent(props){
  const node = props.node
  return(
    <div className={props.className} >
      <h4>{node.Section}</h4>
      <p><b>District Name</b>:<br/> {node.District}</p>
      <p><b>Url</b>: <a href={node.URL}>{node.URL}</a></p>
      <p><b>Address</b>: {node.Address}</p>
      <p><b>Phone Number</b>: <a href={'tel:' + node.Phone_Number}>{node.Phone_Number}</a> </p>
      <p className='small text-navy'>
        <h5><b>Executive Director:</b></h5>  
        <span>{node.Executive_Director}</span><br/>
        <a href={'mailto:' + node.Executive_Director_Email}>{node.Executive_Director_Email}</a><br/>
        <a href={'tel:' + node.Executive_Director_Phone_Number}>{node.Executive_Director_Phone_Number}</a>
      </p>
      <p className='small text-navy'>
        <h5><b>President:</b></h5>  
        <span>{node.President}</span><br/>
        <a href={'mailto:' + node.President_Email}>{node.President_Email}</a><br/>
        <a href={'tel:' + node.President_Phone_Number}>{node.President_Phone_Number}</a>
      </p>
      <p className='small text-navy'>
        <h5><b>Vice President:</b></h5>  
        <span>{node.Vice_President}</span><br/>
        <a href={'mailto:' + node.Vice_President_Email}>{node.Vice_President_Email}</a><br/>
        <a href={'tel:' + node.Vice_President_Phone_Number}>{node.Vice_President_Phone_Number}</a>
      </p>
      <p className='small text-navy'>
        <h5><b>Secretary:</b></h5> 
        <span>{node.Secretary}</span><br/>
        <a href={'mailto:' + node.Secretary_Email}>{node.Secretary_Email}</a><br/>
        <a href={'tel:' + node.Secretary_Phone_Number}>{node.Secretary_Phone_Number}</a>
      </p>
    </div>
  )
}

export default TabContent